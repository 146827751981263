<template>
   <div class="col-12 px-0 mb-1" ref="fechamento">
      <div class="card mb-0" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col-sm text-center text-sm-start cursor-pointer" @click="visualizar">
                  <div class="row align-items-center">
                     <div class="col-12">
                        <h1 class="font-15 mb-0 limitador-1">
                           <span>{{ fechamento.dataInicio == null ? 'Sem data' : fechamento.dataInicio }}</span>
                           <small class="mx-1"> ~ </small>
                           <span>{{ fechamento.dataFim == null ? 'Sem data' : fechamento.dataFim }}</span>
                        </h1>
                        <p class="font-10 mb-0 mb-lg-1 limitador-1 text-secondary"><i class="far fa-hashtag font-9 me-1"></i>{{ fechamento.id }}</p>
                     </div>
                     <div class="col-6">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-store color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Loja:</strong>
                           <span> {{ fechamento.nomeLoja == null ? 'Sem loja' : fechamento.nomeLoja }}</span>
                           <span v-if="fechamento.numeroLoja"><small class="mx-1"> | </small><span><i class="far fa-tag font-9 me-1"></i> {{ fechamento.numeroLoja }}</span></span>
                        </p>
                     </div>
                     <div class="col-6">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-desktop color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Caixa:</strong>
                           <span> {{ fechamento.nomePdv == null ? 'Sem caixa' : fechamento.nomePdv }}</span>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="w-max-content d-flex align-items-center font-16 text-center mx-auto mx-sm-0 mt-2 mt-sm-0">
                  <div class="btn-icone color-theme">
                     <span @click="visualizar"><i class="far fa-print-search"></i><small>Visualizar</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Fechamento',
   props: ['fechamento', 'index'],
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      visualizar : function () {
         this.$emit('visualizar', this.fechamento)
      }
   }
}

</script>