<template>
   <div class="col-12 px-0">
      <div class="card mb-1" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center text-start">
               <div class="col-sm text-center text-sm-start" :class="pagamento.formaPgto == 'CONVÊNIO' ? 'cursor-pointer' : ''" @click="imprimirConvenio">
                  <h1 class="font-14 mb-1 limitador-1 text-uppercase">{{ String(pagamento.formaPgto).replace(/_/g, " ") }}</h1>
                  <p class="font-12 mb-0 limitador-1">
                     <i class="far fa-calendar color-theme font-10 me-1"></i><strong class="subtitle me-1">Data:</strong>
                     <span>{{ pagamento.data.dayOfMonth.toString().padStart(2, '0') +'/'+ pagamento.data.monthValue.toString().padStart(2, '0') +'/'+ pagamento.data.year }} </span>
                     <span>{{ pagamento.data.hour.toString().padStart(2, '0') +':'+ pagamento.data.minute.toString().padStart(2, '0') }}</span>  
                  </p>
               </div>
               <div class="wpx-115 text-center font-16 mx-auto mx-sm-0 mt-2 mt-sm-0">
                  <small>R$ </small>{{ pagamento.valor == null ? '0,00' : parseFloat(pagamento.valor).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
               </div>
               <div class="w-max-content text-center font-16 mx-auto mx-sm-0 mt-2 mt-sm-0" v-if="showEdit">
                  <div class="btn-icone text-red">
                     <span @click="remover" class="py-1 py-sm-2"><i class="fal fa-times"></i><small>Cancelar</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import Swal from 'sweetalert2'

export default {
   name: 'Pagamento',
   props: ['pagamento', 'index', 'showEdit'],
   methods: {
      remover : function () {
         this.$emit('remover', this.index)
      },
      imprimirConvenio : function () {
         if (this.pagamento.formaPgto == 'CONVÊNIO') {
            Swal.fire({
               icon: 'info',
               title: 'Imprimir comprovante?',
               showCancelButton: true,
               confirmButtonText: 'Sim',
               cancelButtonText: `Não`
               
            }).then((result) => {
               if (result.isConfirmed) {
                  this.$emit('imprimirConvenio', this.pagamento)
               }
            })
         }
      }
   }
}

</script>