<template>
   <div class="row m-0 bg-white text-dark rounded">
      <div class="col-12 px-0 m-3" style="width: 350px; font-size: 12px;">
         <div class="row m-0 align-items-center text-center">
            <div class="col-12 px-1" style="font-size: 14px;">{{ String(convenio.nomeLoja).toUpperCase() }}</div>
            <div class="col-12 px-1 mb-2">Número loja: {{ convenio.numeroLoja }} - Caixa: {{ convenio.nomePdv }}</div>
         </div>

         <hr class="bg-transparent my-1" style="border: 1px dashed #000;">
         
         <div class="row m-0 align-items-center">
            <div class="col-12 px-1 text-center">Convênio</div>
         </div>
         
         <hr class="bg-transparent my-1" style="border: 1px dashed #000;">
         
         <div class="row m-0 align-items-center">
            <div class="col-4 px-1">Nome Beneficiário:</div>
            <div class="col-8 px-1 text-end text-truncate">{{ convenio.nome }}</div>
            <div class="col-4 px-1">Doc. Beneficiário:</div>
            <div class="col-8 px-1 text-end text-truncate">{{ convenio.cpfCnpj }}</div>
            <div class="col-4 px-1">Data:</div>
            <div class="col-8 px-1 text-end text-truncate">
               <span v-if="convenio.data">{{ convenio.data.dayOfMonth.toString().padStart(2, '0') +'/'+ convenio.data.monthValue.toString().padStart(2, '0') +'/'+ convenio.data.year }} </span>
               <span v-if="convenio.data">{{ convenio.data.hour.toString().padStart(2, '0') +':'+ convenio.data.minute.toString().padStart(2, '0') }}</span>  
               <span v-else>Desconhecido</span>  
            </div>
            <div class="col-4 px-1">Valor Pago:</div>
            <div class="col-8 px-1 text-end text-truncate">R$ {{ parseFloat(convenio.valor).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</div>
         </div>

         <hr class="bg-transparent mb-1 mt-2" style="border: 1px dashed #000;">
         
         <div class="row m-0 align-items-center">
            <div class="col-12 px-1 text-center">Eu {{ convenio.nome }} me declaro devedor do seguinte valor R$ {{ parseFloat(convenio.valor).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</div>
         </div>
         
         <hr class="bg-transparent my-1" style="border: 1px dashed #000;">
      </div>
   </div>
</template>

<script>

export default {
	name: 'ComprovanteConvenio',
	props: ['convenio']
}

</script>